import '../assets/css/nds.css';

import React, { Component } from "react"
import SignatureCanvas from 'react-signature-canvas'
import Formsy from 'formsy-react';
import { connect } from "react-redux"
import { navigate } from "gatsby"

import Slider from 'rc-slider';
import '../assets/css/slider.css';

import Layout from "../components/layout"
import Steps from "../components/ndstep"
import bottomLine from "../images/line.png"
import SEO from "../components/seo"
import * as actionCreators from './../store/actions';
import MyInput from "../components/MyInput"
import MyCheckbox from "../components/MyCheckbox";
import MyInputMask from "../components/MyInputMask";
import ButtonLoader from "../components/buttonLoader";
import infosvg from "../images/info.svg"


class nds5 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      canSubmit: false,
      newsub:false,
      infoError:null,
      nds5Data:{name:'',title:'',phone:'',email:'',w9form:false,dealStructure:'0',products:[]},
      selectedFile:null,
      marketVal:3, 
      marketDet:{
        1:{title:"Very Soft Approach", desc:"Lowest results, email only."},
        2:{title:"Soft Approach", desc:"Email and light phone activity."},
        3:{title:"Moderate Approach", desc:"Email, phone, and texting activity."},
        4:{title:"Active Approach", desc:"Increased email, phone, and texting activity."},
        5:{title:"Gung-Ho!", desc:"Persistent email, phone, and texting activity; best results!"}
      }
    };
   
  }
  sigPad = {}
  
  componentDidMount = async () => {
    if(!sessionStorage.nds4Data){
      navigate("/nds1"+(global.dealertoken?'/?dealertoken='+global.dealertoken:''));
    }
    if(!sessionStorage.ndsver || sessionStorage.ndsver!==process.env.ndsver){
      sessionStorage.clear();
      sessionStorage.ndsver=process.env.ndsver;
    }
    if(!sessionStorage.nds5Data){
        sessionStorage.nds5Data = window.btoa(JSON.stringify(this.state.nds5Data))
    } 
    this.state.nds5Data = JSON.parse(window.atob(sessionStorage.nds5Data))
    
    this.setState({nds5Data:JSON.parse(window.atob(sessionStorage.nds5Data))});

    let isAgent = sessionStorage.isAgent==='yes'?true:false;
    this.setState({isAgent:isAgent})
    console.log(process.env.BEDFORD_API_URL+"5a");
    
  }

  
  clear = () => {
    this.sigPad.clear();
    this.setState({ canSubmit: false });
    //this.checkMealso();
  }
  

  checkMealso = () => {
    let drw = this.sigPad.getTrimmedCanvas().toDataURL('image/png');
    if(drw.length>5000){
      this.setState({ canSubmit: true });
    }
  }

  
  submit = async(model) => {
    const { isAgent } = this.state;
        const { showLoader, hideLoader} = this.props;
        showLoader();
        const data = new FormData();
        let prds = JSON.parse(window.atob(sessionStorage.nds3Data));
        let i = prds.ProductActivate.indexOf("Other");
        prds.ProductActivate[i] = "Other : "+prds.Other;

        //console.log(prds.ProductActivate);
        let info = {
            'producer': JSON.parse(window.atob(sessionStorage.nds1Data)),
            'contact': JSON.parse(window.atob(sessionStorage.nds2Data)),
            'products': prds,
            'dmsType': JSON.parse(window.atob(sessionStorage.nds4Data)),
            'payee':{}
        }
        if(!isAgent)
          data.append('sign',this.sigPad.getTrimmedCanvas().toDataURL('image/png'))
        data.append('issc',0)
        data.append('agrinfo',JSON.stringify(info))
        data.append('agrinfo',JSON.stringify(info))
        
      await fetch(process.env.BEDFORD_API_URL,{method: "POST", body: data})
      .then(res => res.json())
      .then((res) => {
        //sessionStorage.clear();
        hideLoader();
        navigate("/ndsend");
      });
    
  }

  onChangeHandler=event=>{
    this.setState({ selectedFile: event.target.files[0]})
    this.state.selectedFile = event.target.files[0];
    //this.checkMealso();
  }
   
  render() {
    const { canSubmit, newsub, infoError, isAgent } = this.state;
    const { buttonLoader} = this.props;

    
   
    return (
      <Layout layoutType="nds">
        <section className="ndsec">
          <div className="custom-container vehinfo">
            <div className="title-container">
              {/* <div className="icon-image">
                <img src={vehicalIcon} alt="icon" />
              </div> */}
              <div className="title-text">
                <h2>{'Business Owner'}</h2>
              </div>
            </div>

            <div className="body-content">
                
            <Formsy ref="nds5Form" onValidSubmit={this.submit} onValid={this.enableButton} onInvalid={this.disableButton}>
                <div className="form-container-inline">
                    <div className="form-left">
                    <table className="reable">
                        <td>
                            <table>
                                <tr>
                                    <th>Legal Name</th><td> : My Name</td>
                                </tr>
                            </table>
                        </td>
                        <td>
                            <table>
                                <tr>
                                    <th>Legal Name</th><td> : My Name</td>
                                </tr>
                            </table>
                        </td>
                        
                    </table>    
                    </div>
                    <div className="form-right">
                    <div className="form-label-group error">
                        <label>Signature *</label>
                        <small>
                        By signing here, you agree to sign the Producer Agreement reflecting the information you provided which you represent it accurate and correct, and understand that your signature will be applied to the Agreement. Information needed to complete the exhibits to the Agreement, as applicable, will be gathered during the remainder of the onboarding process and forwarded for signature upon completion.
                        <a href="https://dowc-asserts.s3.amazonaws.com/ProducerAgreement20.pdf" target="_blank">(Sample Agreement)</a>
                        </small>
                        
                        <SignatureCanvas canvasProps={{className: 'bosign'}} 
                        throttle={0} dotSize={2} minWidth={0.1} maxWidth={2} minDistance={1}
                        ref={(ref) => { this.sigPad = ref }}
                        onEnd={(e) => this.checkMealso()}
                        />
                        <a className="clearbtn" onClick={this.clear}>Clear</a>
                        
                        
                    </div>

                    <div className="error-txt">{infoError}</div>
                    <div className="btn-container">
                        <button type="submit" disabled={!canSubmit || buttonLoader} className={!canSubmit || buttonLoader ? 'btn disabled wizard-btn creat-btn' : 'btn wizard-btn creat-btn'}>
                            <ButtonLoader isLoading={buttonLoader} label={'Submit'} />
                        </button>
                    </div>  
                        
                    </div>
                    
                </div>
            
    
                
            </Formsy>

              <div className="txtright">* Field is required</div>
            </div>
          </div>
          <div className="vehicle-bottom-line bottom-line">
            <img src={bottomLine} alt="line" />
          </div>
        </section>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    pageLoader: state.primary.pageLoader,
    buttonLoader: state.primary.buttonLoader
  }
};

const mapDispatchToProps = dispatch => {
  return {
    showLoader: () => dispatch(actionCreators.showLoader()),
    hideLoader: () => dispatch(actionCreators.hideLoader()),
    
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(nds5);